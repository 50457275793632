/* eslint-disable */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/helpers/linkResolver');

registerLinkResolver(linkResolver);

// You can delete this file if you're not using it
exports.onClientEntry = () => {
  // NOTE: Don't polyfill Promise here (Gatsby already includes a Promise polyfill)

  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    // eslint-disable-next-line global-require
    require(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    // eslint-disable-next-line global-require
    require(`object-fit-images`)()
    console.log(`👍 Object-fit/Object-position are polyfilled`)
  }

  // Not sure why but babel-polyfill does not polyfill Number.isInteger so this fixes it...
  // Review this at a later stage and see if it is fixed.
  Number.isInteger = Number.isInteger || function (value) {
    return typeof value === 'number' &&
      isFinite(value) &&
      Math.floor(value) === value;
  };

  // String startsWith polyfill
  if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
      position = position || 0;
      return this.indexOf(searchString, position) === position;
    };
  }
}

exports.onRouteUpdate = () => {
  if (process.env.GATSBY_ACTIVE_ENV === "production") {
    let img = document.createElement("img");
    img.src = "https://secure.adnxs.com/px?id=1169150&seg=19417968&redir=https%3A%2F%2Fpixel.mediaiqdigital.com%2Fpixel%3Fu1%3D[u1]%26pixel_id%3D1169150%26uid%3D%24%7BUID%7D&t=2";
    img.style.width = '1px';
    img.style.height = '1px';

    document.body.appendChild(img);
  }
};
