// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-en-pages-home-solutions-blog-js": () => import("./../src/en/pages/home-solutions/blog.js" /* webpackChunkName: "component---src-en-pages-home-solutions-blog-js" */),
  "component---src-fr-pages-home-solutions-blog-js": () => import("./../src/fr/pages/home-solutions/blog.js" /* webpackChunkName: "component---src-fr-pages-home-solutions-blog-js" */),
  "component---src-en-pages-index-js": () => import("./../src/en/pages/index.js" /* webpackChunkName: "component---src-en-pages-index-js" */),
  "component---src-en-pages-about-royale-js": () => import("./../src/en/pages/about-royale.js" /* webpackChunkName: "component---src-en-pages-about-royale-js" */),
  "component---src-en-pages-privacy-policy-js": () => import("./../src/en/pages/privacy-policy.js" /* webpackChunkName: "component---src-en-pages-privacy-policy-js" */),
  "component---src-en-pages-terms-of-service-js": () => import("./../src/en/pages/terms-of-service.js" /* webpackChunkName: "component---src-en-pages-terms-of-service-js" */),
  "component---src-en-pages-past-winners-js": () => import("./../src/en/pages/past-winners.js" /* webpackChunkName: "component---src-en-pages-past-winners-js" */),
  "component---src-en-pages-faq-js": () => import("./../src/en/pages/faq.js" /* webpackChunkName: "component---src-en-pages-faq-js" */),
  "component---src-en-pages-products-index-js": () => import("./../src/en/pages/products/index.js" /* webpackChunkName: "component---src-en-pages-products-index-js" */),
  "component---src-en-pages-home-solutions-index-js": () => import("./../src/en/pages/home-solutions/index.js" /* webpackChunkName: "component---src-en-pages-home-solutions-index-js" */),
  "component---src-en-pages-refreshyourspace-js": () => import("./../src/en/pages/refreshyourspace.js" /* webpackChunkName: "component---src-en-pages-refreshyourspace-js" */),
  "component---src-en-pages-springcleaningoffers-js": () => import("./../src/en/pages/springcleaningoffers.js" /* webpackChunkName: "component---src-en-pages-springcleaningoffers-js" */),
  "component---src-en-pages-pets-js": () => import("./../src/en/pages/pets.js" /* webpackChunkName: "component---src-en-pages-pets-js" */),
  "component---src-fr-pages-index-js": () => import("./../src/fr/pages/index.js" /* webpackChunkName: "component---src-fr-pages-index-js" */),
  "component---src-fr-pages-about-royale-js": () => import("./../src/fr/pages/about-royale.js" /* webpackChunkName: "component---src-fr-pages-about-royale-js" */),
  "component---src-fr-pages-privacy-policy-js": () => import("./../src/fr/pages/privacy-policy.js" /* webpackChunkName: "component---src-fr-pages-privacy-policy-js" */),
  "component---src-fr-pages-terms-of-service-js": () => import("./../src/fr/pages/terms-of-service.js" /* webpackChunkName: "component---src-fr-pages-terms-of-service-js" */),
  "component---src-fr-pages-past-winners-js": () => import("./../src/fr/pages/past-winners.js" /* webpackChunkName: "component---src-fr-pages-past-winners-js" */),
  "component---src-fr-pages-faq-js": () => import("./../src/fr/pages/faq.js" /* webpackChunkName: "component---src-fr-pages-faq-js" */),
  "component---src-fr-pages-products-index-js": () => import("./../src/fr/pages/products/index.js" /* webpackChunkName: "component---src-fr-pages-products-index-js" */),
  "component---src-fr-pages-home-solutions-index-js": () => import("./../src/fr/pages/home-solutions/index.js" /* webpackChunkName: "component---src-fr-pages-home-solutions-index-js" */),
  "component---src-fr-pages-refreshyourspace-js": () => import("./../src/fr/pages/refreshyourspace.js" /* webpackChunkName: "component---src-fr-pages-refreshyourspace-js" */),
  "component---src-fr-pages-springcleaningoffers-js": () => import("./../src/fr/pages/springcleaningoffers.js" /* webpackChunkName: "component---src-fr-pages-springcleaningoffers-js" */),
  "component---src-fr-pages-pets-js": () => import("./../src/fr/pages/pets.js" /* webpackChunkName: "component---src-fr-pages-pets-js" */),
  "component---src-zh-hant-pages-index-js": () => import("./../src/zh-hant/pages/index.js" /* webpackChunkName: "component---src-zh-hant-pages-index-js" */),
  "component---src-zh-hant-pages-about-royale-js": () => import("./../src/zh-hant/pages/about-royale.js" /* webpackChunkName: "component---src-zh-hant-pages-about-royale-js" */),
  "component---src-zh-hant-pages-faq-js": () => import("./../src/zh-hant/pages/faq.js" /* webpackChunkName: "component---src-zh-hant-pages-faq-js" */),
  "component---src-zh-hant-pages-terms-of-service-js": () => import("./../src/zh-hant/pages/terms-of-service.js" /* webpackChunkName: "component---src-zh-hant-pages-terms-of-service-js" */),
  "component---src-zh-hant-pages-privacy-policy-js": () => import("./../src/zh-hant/pages/privacy-policy.js" /* webpackChunkName: "component---src-zh-hant-pages-privacy-policy-js" */),
  "component---src-zh-hant-pages-past-winners-js": () => import("./../src/zh-hant/pages/past-winners.js" /* webpackChunkName: "component---src-zh-hant-pages-past-winners-js" */),
  "component---src-zh-hant-pages-products-index-js": () => import("./../src/zh-hant/pages/products/index.js" /* webpackChunkName: "component---src-zh-hant-pages-products-index-js" */),
  "component---src-zh-hans-pages-index-js": () => import("./../src/zh-hans/pages/index.js" /* webpackChunkName: "component---src-zh-hans-pages-index-js" */),
  "component---src-zh-hans-pages-about-royale-js": () => import("./../src/zh-hans/pages/about-royale.js" /* webpackChunkName: "component---src-zh-hans-pages-about-royale-js" */),
  "component---src-zh-hans-pages-faq-js": () => import("./../src/zh-hans/pages/faq.js" /* webpackChunkName: "component---src-zh-hans-pages-faq-js" */),
  "component---src-zh-hans-pages-terms-of-service-js": () => import("./../src/zh-hans/pages/terms-of-service.js" /* webpackChunkName: "component---src-zh-hans-pages-terms-of-service-js" */),
  "component---src-zh-hans-pages-privacy-policy-js": () => import("./../src/zh-hans/pages/privacy-policy.js" /* webpackChunkName: "component---src-zh-hans-pages-privacy-policy-js" */),
  "component---src-zh-hans-pages-past-winners-js": () => import("./../src/zh-hans/pages/past-winners.js" /* webpackChunkName: "component---src-zh-hans-pages-past-winners-js" */),
  "component---src-zh-hans-pages-products-index-js": () => import("./../src/zh-hans/pages/products/index.js" /* webpackChunkName: "component---src-zh-hans-pages-products-index-js" */),
  "component---src-en-pages-products-product-js": () => import("./../src/en/pages/products/product.js" /* webpackChunkName: "component---src-en-pages-products-product-js" */),
  "component---src-zh-hant-pages-products-product-js": () => import("./../src/zh-hant/pages/products/product.js" /* webpackChunkName: "component---src-zh-hant-pages-products-product-js" */),
  "component---src-zh-hans-pages-products-product-js": () => import("./../src/zh-hans/pages/products/product.js" /* webpackChunkName: "component---src-zh-hans-pages-products-product-js" */),
  "component---src-fr-pages-products-product-js": () => import("./../src/fr/pages/products/product.js" /* webpackChunkName: "component---src-fr-pages-products-product-js" */),
  "component---src-en-pages-products-category-js": () => import("./../src/en/pages/products/category.js" /* webpackChunkName: "component---src-en-pages-products-category-js" */),
  "component---src-zh-hant-pages-products-category-js": () => import("./../src/zh-hant/pages/products/category.js" /* webpackChunkName: "component---src-zh-hant-pages-products-category-js" */),
  "component---src-zh-hans-pages-products-category-js": () => import("./../src/zh-hans/pages/products/category.js" /* webpackChunkName: "component---src-zh-hans-pages-products-category-js" */),
  "component---src-fr-pages-products-category-js": () => import("./../src/fr/pages/products/category.js" /* webpackChunkName: "component---src-fr-pages-products-category-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

